<template>
  <div class="interview">
    <h2 class="interview__main-title" align="center">Interview</h2>

    <section class="interview__section">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          What were your homes like growing up?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            I grew up in a deservedly forgotten architect’s nondescript West Orange, New Jersey
            home, decorated in my mother’s indecision with an all-pink master bedroom.
          </span>
        </div>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            The spacious home I grew up in the gracious Palmer Woods neighborhood of Detroit was of
            combined Tudor revival, Neo-Georgian and arts-and-crafts architecture with a healthy
            dose of hand-painted Pewabie tiles throughout. My parents furnished its spaces with a
            tasteful monotone assemblage of purportedly English antiques they were ever rearranging.
            Unlike the broad expanses of glass that made birdwatchers out of Lou and I, the
            traditional small-paned windows I grew up with largely limited my view out to mother’s
            manicured gardens and their beautiful peacock population.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__image-box left">
        <img
          class="interview__section__image-box_image"
          v-webp="require('@/assets/images/interview/sandy_and_lou.png')"
          src="@/assets/images/interview/sandy_and_lou.png"
          alt="Early portrait of Sandy and Lou"
          decoding="async"
          loading="lazy"
        />
        <span class="interview__section__image-box_title" align="center">
          Early portrait of Sandy and Lou
        </span>
      </div>

      <div class="interview__section__inerview-box" style="max-width: 62%">
        <h3 class="interview__section__inerview-box_question">
          When and where did you two get together?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            At the University of Michigan in 1953 when a friend fixed up a more-drinker-than-dater
            junior, liquid Lou Grotta.
          </span>
        </div>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            With an as-much-as-she-wanted-to-be-popular sophomore, Sandy Brown.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          When you arrived in Ann Arbor, did either of you bring along an interest in architecture,
          art and design?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            Frankly, no, My eyes were mainly centered on shopping and boys. I never ever got around
            to visiting Cranbrook until I was well into my junior year in Ann Arbor.
          </span>
        </div>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            On a couple of occasions, my mother force marched me to the Metropolitan Museum to
            appreciate Rembrandt and company. I was duly impressed by the Museum’s soaring
            architecture but its contents never came close to replacing Ebbets Field in Brooklyn
            when my beloved Dodgers were in play as my favorite field of vision.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          Was there a catalyst that tweaked your joint interests in art and architecture?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            It was at the insistence of many of our non-art-major Michigan friends that we signed up
            for a couple of Professor Marvin Eisenber’s “Introduction to the History of Art and
            Architecture” courses. His rollicking, illuminating grand tour from Raphael’s School of
            Athens to Edward Munch’s Scream, from the Parathenon to the Pantheon to Falling Water
            opened our eyes to wo lifetimes of great looking.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          How would you describe your first home?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            Not likely to appear on the cover of Architectural Record. It was a 30-year-old,
            center-hall colonial whose main attractions were its sensible layout, its proximity to
            my sister’s house, a very good neighborhood grammar school and its proximity to the
            outer limits of our budget.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__inerview-box" style="max-width: 62%">
        <h3 class="interview__section__inerview-box_question">
          How would you describe your first home?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            Not likely to appear on the cover of Architectural Record. It was a 30-year-old,
            center-hall colonial whose main attractions were its sensible layout, its proximity to
            my sister’s house, a very good neighborhood grammar school and its proximity to the
            outer limits of our budget.
          </span>
        </div>
      </div>

      <div class="interview__section__image-box right">
        <img
          class="interview__section__image-box_image"
          v-webp="require('@/assets/images/interview/their_first_home.png')"
          src="@/assets/images/interview/their_first_home.png"
          alt="Their First Home"
          decoding="async"
          loading="lazy"
        />
        <span class="interview__section__image-box_title" align="center">Their First Home</span>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          Did you both agree on the way your home should be decorated?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            I wanted it done in the spirit of my parent’s home only, not as formal.
          </span>
        </div>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            I wanted modern, with preferably contemporary Scandinavian furniture and no signs of
            pink in our master bedroom or anywhere else.
          </span>
        </div>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            Our mediator was Lou’s mom’s decorator, who well dressed it in a beige-backgrounded,
            proper-taste-of-the-time manner.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section column">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          When did the craft bug first bite you two?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            In the early 1960s, walking out of the Museum of Modern Art, we stumbled upon the Museum
            of Contemporary Craft next door. The Museum’s exhibitions, many of whose objects were
            for sale in its store, caused a case of love at first sight. It quickly became a
            founding source of many craft purchases to follow. It was also the site of our initial
            sighting of the wonderful walnut wood word of Edgar and Joyce Anderson. Soon after we
            commissioned the first of what evolved into our becoming collectors of the majority of
            Joyce and Shorty’s limited output for the next 30 years. Beginning with their friends,
            Toshiko Takeazu and William Wyman, the Andersons were our bridge to other major makers
            in what we believe to have been the golden age of contemporary crafts and the impetus to
            my becoming our decorator, going to interior design school and entering the field.
          </span>
        </div>
      </div>

      <div class="interview__section__image-box">
        <img
          class="interview__section__image-box_image indent"
          v-webp="require('@/assets/images/interview/front_hall.png')"
          src="@/assets/images/interview/front_hall.png"
          alt="Front hall at 31 Woodhill:  Work by William Daley, Toshiko Takaezu, Tom Wesselman, Mariette Rousseau-Vermette, John McQueen, Chief Don Lelooska"
          decoding="async"
          loading="lazy"
        />
        <span class="interview__section__image-box_title" align="center">
          Front hall at 31 Woodhill: Work by William Daley, Toshiko Takaezu, Tom Wesselman, Mariette
          Rousseau-Vermette, John McQueen, Chief Don Lelooska
        </span>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          What changed in your home when you became its head orchestrator?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            Just about everything. All of the wallpaper, molding, drapes, lamps, filigree and
            furniture went, as did our beige background, which I immediately brightened to white.
            The Andersons’ and friends’ fingerprints gradually appeared upstairs and down. A trip to
            Sandinavia with my father brought home a not as good-playing but far better-looking
            pano, the first of many Hans Wegner chairs and one of Kay Bojensen’s biggest monkeys. My
            love of Charles Loloma’s incredible jewelry launched me headfirst into the world of
            American Indian art, Southwest and Northwest, A far-off-the-beaten-path trip to Ariel,
            Washington led to our commissioning Chief Don Lelooska to carve an eight-foot-tall totem
            pole for our front hall. By the time we moved in 1989, our home was filled to capacity
            with wood, fiber and ceramic works, functional and non-functional, by many of the major
            architects, industrial designers and craft creators of our vintage.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section column">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          Did you always envision building your own dream house?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            Yes, but the finished product turned out far different than the way we pictured it. We
            always saw ourselves setted into a wood and irregular stone modern house on maybe a
            little larger lot in a nearby neighborhood designed by a very good architect not named
            Richard Meier. As it turned out, we wound up in horse county on seven-and-a-half acres.
            The only wood in our construction was its framing, totally concealed under
            32-inch-square white, porcelain-enameled steel panels, and our stone couldn’t be more
            regularly shaped cinder block and slate. Our very good architect, when push came to
            shove, was the once were most comfortable with personally-my old late 1940s summer camp
            bunkmate better known then as Dickie Meier.
          </span>
        </div>
      </div>

      <div class="interview__section__image-box">
        <img
          class="interview__section__image-box_image indent"
          v-webp="require('@/assets/images/interview/kennebec_camp.png')"
          src="@/assets/images/interview/kennebec_camp.png"
          alt="1949 Kennebec camp yearbook: Richard Meier and Lou Grotta"
          decoding="async"
          loading="lazy"
        />
        <span class="interview__section__image-box_title" align="center">
          1949 Kennebec camp yearbook: Richard Meier (left) and Lou Grotta (right)
        </span>
      </div>
    </section>

    <section class="interview__section column">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          How have the contents of your home changed over the years?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            Our original settlers continue to make up a significant percentage of our expanded craft
            population, but they very well may not be located in the same place today or tomorrow.
            Through the years, change has been our constant for a number of good reasons. It gives a
            fresh perspective to individual pieces and their total environment, and it’s easy to
            seamlessly make that happen. Except for the color blue and a small wip of yellow, most
            of our things come only in their neutral wood, ceramic, and basketry colors. Barring a
            mismatch in scale, our things have a way of quickly telling us, appearance-wise and
            quality-wise, if they rightly belong in the company of their neighbors. Speaking of
            location, two of our major ceramic wall pieces- a subtle Paul Soldner raku and a large
            black rabbit plate by Ken Ferguson-could never be fully appreciated from a distance.
            Paul and Ken might not have been enamored by their present setting, but there is no
            question they are both best viewed staring straight ahead while sitting on the throne of
            our tight downstairs bathroom.
          </span>
        </div>
      </div>

      <div class="interview__section__image-box">
        <img
          class="interview__section__image-box_image indent"
          v-webp="require('@/assets/images/interview/tapestry_and_sculpture.png')"
          src="@/assets/images/interview/tapestry_and_sculpture.png"
          alt="Tapestry by Mariette Rousseau-Vermette; sculpture by Judy Mulford"
          decoding="async"
          loading="lazy"
        />
        <span class="interview__section__image-box_title" align="center">
          Tapestry by Mariette Rousseau-Vermette; sculpture by Judy Mulford
        </span>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          Has there been a shift in emphasis media-wise over the years?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            Our son, Tom, started his contemporary fiber gallery about the time we moved into the
            house. His inherently great taste has changed the ratio of our media make-up
            dramatically in both scale and scope. The latest example being our recent installation
            of a never previously displayed 38-year-old, 10 foot tapestry by our great friend
            Mariette Rousseau-Vermette.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__image-box left" style="width: 45%">
        <img
          class="interview__section__image-box_image"
          v-webp="require('@/assets/images/interview/door_handle.png')"
          src="@/assets/images/interview/door_handle.png"
          alt="Door handle collaboration: Edgar Anderson and Richard Meier"
          decoding="async"
          loading="lazy"
        />
        <span class="interview__section__image-box_title" align="center">
          Door handle collaboration: Edgar Anderson and Richard Meier
        </span>
      </div>

      <div class="interview__section__inerview-box" style="max-width: 57%">
        <h3 class="interview__section__inerview-box_question">
          Was there much collaboration between your architect and craft makers?
        </h3>

        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            The closest the two came to collaborating was on a door handle design. It evolved from a
            postcard architectural drawing of the house that Richard transformed into a possibility
            that Shorty Anderson reshaped into wood maquette which Richard re-tweaked to be
            ultimately handcast in stainless steel. Throughout the house, there are a lot of things
            designed by well-known architects. It is easy and interesting to note the difference in
            materials and approach between den compared to Richard’s all encompassing wall-to-wall
            headboard in our bedroom.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          Over the 30 years you have been in your home, have there been many architectural changes?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            Very few. Most noticeably the conversion of our front doors from wood to glass. In
            addition to letting in even more light, it dramatically expanded our entry view all the
            way through the house out to the architectural sculpture on the other side and vice
            versa. In the case of our back door, it did wonders for the visibility of our favorite
            tree. Mother Nature was always out there in our other homes, but it took Meier’s
            architecture to truly expose her full repertoire from lightning storms to spring flowers
            to sly foxes to an occasional bear. The weeping white pine tree between the house and
            the garage originally topped out at garage height. Over time it’s gracefully expanded to
            more than double in size. Not only does it look better, it’s become our birds favorite
            habitat and a beautiful don’t-have-to-move around umbrella for many of our most
            memorable meals. Even on the sunniest of days, we enjoy dining out off of the trays of
            our portable chairs that conveniently fold up and go away when we do, leaving our tree
            visually free of any man-made competition until our next meal. Coming up the stairs to
            the second floor, or looking back out through the many Toshikos in our master bedroom
            window, there stands an equally admirable dining venue-an allé of white birch trees well
            laid out by our long-time landscape architect Walter Carrell. Even when their restaurant
            is closed and their leaves have fallen, Walter’s bichers remain and outstanding
            presence.
          </span>
        </div>
      </div>
    </section>

    <section class="interview__section column">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          Sandy, did your jewelry always hang all throughout your house?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Sandy: </span>
          <span class="interview__section__inerview-box__item_line">
            For most of our married life, my jewelry, if it wasn’t on me making me look good and
            feel better, went into hibernation, a lot of pieces comfortably secluded within the wood
            “chest of drawers” that the Andersons designed to house them. As a consequence, over
            time many of my smaller things suffered from being out of sight, out of mind. My jewelry
            revolution came the first time I hung one of David Wakins’ necklaces on a wall in the
            company of a number of my craft-media heavy weights. I instantly realized how well
            David’s creation more than held its own, as would the work of significant other jewelers
            led by his most significant other, his wife, Wendy Ramshaw. Although her individual
            pieces lacked the scale of David’s, her had-to-have line up of earrings and rings, each
            on its own integral Brancusi-like stand, reflected in the mirror of my dressing room
            table and never fails to put a smile on my face. When it came to displaying my
            bracelets, I turned to Thom Hucker, who did a tremendous job as he did on his
            off-the-wall, on-the-wall outing of my rings. His latest slant for Gerd Rothman’s YCHEJ
            necklace subtly conveys a long-time tenet of yours truly: You can’t have enough jewelry.
          </span>
        </div>
      </div>

      <div class="interview__section__image-box">
        <img
          class="interview__section__image-box_image indent"
          v-webp="require('@/assets/images/interview/jewelry_and_necklace.png')"
          src="@/assets/images/interview/jewelry_and_necklace.png"
          alt="Jewelry displays by Thomas Hucker; necklace by Gerd Rothman; bracelets by assorted artists"
          decoding="async"
          loading="lazy"
        />
        <span class="interview__section__image-box_title" align="center">
          Jewelry displays by Thomas Hucker; necklace by Gerd Rothman; bracelets by assorted artists
        </span>
      </div>
    </section>

    <section class="interview__section column">
      <div class="interview__section__inerview-box">
        <h3 class="interview__section__inerview-box_question">
          When it comes to aesthetic decisions, do you two often disagree?
        </h3>
        <div class="interview__section__inerview-box__item">
          <span class="interview__section__inerview-box__item_person">Lou: </span>
          <span class="interview__section__inerview-box__item_line">
            Rarely. Since day one, we’ve always been blessed with an amazing like/dislike simpatico.
            On the rare occasions we disagree, we honor the other’s veto power. It’s true, I do
            believe you can have enough jewelry, but when a must-have piece comes along, I
            invariably look the other way.
          </span>
        </div>
      </div>

      <div class="interview__section__image-box">
        <img
          class="interview__section__image-box_image indent"
          v-webp="require('@/assets/images/interview/lou_grotta.png')"
          src="@/assets/images/interview/lou_grotta.png"
          alt="Lou Grotta"
          decoding="async"
          loading="lazy"
        />
        <span class="interview__section__image-box_title" align="center">Lou Grotta</span>
      </div>
    </section>

    <span class="interview__quote">
      “We’re visually spoiled. Foreground, middle ground, background, there’s an ever-evolving
      interplay, a harmony. We inhabit an autobiography of our marriage and Meier’s magical
      architecture animated by Mother Nature’s greatest show on earth. Wherever we look, whenever we
      look, it never gets old, and, unlike its owners, it continues to improve with age.”
    </span>

    <span class="interview__info">
      Originally appeared in “Interview with the Grottas,” The Grotta Home by Richard Meier: a
      marriage of architecture and craft (Arnoldsche Art Publishers, Stuttgart, Germany, 2019), pp.
      9 - 27.
    </span>

    <div class="interview__navigation-container">
      <span class="interview__navigation-container_title">Next: View from Inside</span>
      <div class="interview__navigation-container_buttons-box">
        <BaseButton
          class="button--black-grey button--oval"
          text="Inside"
          @click="openInteriorsPage"
        />
        <BaseButton
          class="button--black-grey button--oval"
          text="Categories"
          @click="openCategories"
        />
        <BaseButton
          class="button--outline button--oval"
          text="Back to Home"
          @click="openHomePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    BaseButton,
  },

  head: {
    title: function () {
      return {
        inner: "GH - Interview",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Interview",
        to: "/interview",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),

    openInteriorsPage() {
      this.$router.push({ name: "Interiors", params: { id: 1 } });
    },

    openCategories() {
      this.$router.push({ path: "/categories" });
      //this.$router.push({ path: "/categories/collection" });
    },

    openHomePage() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.interview {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 21px;
  line-height: 1.66;
  background-color: $white;
  padding: 20.32rem 0rem 11.72rem;
  @media only screen and (max-width: $xs) {
    font-size: 18px;
    line-height: 1.55;
    padding: 18rem 0rem 10rem;
  }

  &__main-title {
    font-size: 62px;
    line-height: 1.1;
    font-family: $fontBodoni;
    font-weight: 500;
    margin-bottom: 10px;
    @media (max-width: $xs) {
      padding: 0rem 2.8rem;
      font-size: 4rem;
      line-height: 6rem;
    }
  }

  &__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 65px;
    padding: 0rem 36.46rem;
    @media only screen and (max-width: $md) {
      padding: 0rem 12rem;
    }
    @media only screen and (max-width: $sm) {
      flex-direction: column;
    }
    @media only screen and (max-width: $xs) {
      padding: 0rem 2.8rem;
    }

    &.column {
      flex-direction: column;
    }

    &__image-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media only screen and (max-width: $sm) {
        width: 100% !important;
      }

      &.left {
        width: 35%;
        margin-right: 35px;
        @media only screen and (max-width: $xs) {
          margin: 0px 0px 35px;
        }
      }

      &.right {
        width: 35%;
        margin-left: 35px;
        @media only screen and (max-width: $xs) {
          margin: 35px 0px 0px;
        }
      }

      &_image {
        width: 100%;

        &.indent {
          margin-top: 55px;
        }
      }

      &_title {
        font-size: 16px;
        line-height: 1.55;
        margin-top: 4px;
      }
    }

    &__inerview-box {
      @media only screen and (max-width: $sm) {
        max-width: 100% !important;
      }
      &_question {
        font-size: 25px;
        line-height: 1.4;
        font-family: $fontBodoni;
        font-weight: 600;
      }

      &__item {
        margin-top: 35px;
        text-align: justify;
        @media only screen and (max-width: $xs) {
          text-align: left;
        }

        &:nth-child(2) {
          margin-top: 20px;
        }

        &_person {
          font-size: 25px;
          line-height: 1.4;
          font-family: $fontBodoni;
          font-weight: 500;
        }

        &_line {
          letter-spacing: -0.35px;
        }
      }
    }
  }

  &__quote {
    font-size: 25px;
    line-height: 1.4;
    font-family: $fontBodoni;
    letter-spacing: -0.45px;
    font-weight: 600;
    margin-top: 65px;
    padding: 0rem 36.46rem;
    @media only screen and (max-width: $md) {
      padding: 0rem 12rem;
    }
    @media only screen and (max-width: $xs) {
      padding: 0rem 2.8rem;
    }
  }

  &__info {
    font-size: 21px;
    line-height: 1.43;
    letter-spacing: -0.35px;
    margin-top: 105px;
    padding: 0rem 36.46rem;
    @media only screen and (max-width: $md) {
      padding: 0rem 12rem;
    }
    @media only screen and (max-width: $xs) {
      padding: 0rem 2.8rem;
    }
  }

  &__navigation-container {
    display: flex;
    flex-direction: column;
    margin: 19.5rem 0rem 10.5rem;
    padding-left: 25%;
    @media only screen and (max-width: $xs) {
      margin: 7.6rem 0rem 8.9rem;
      padding-left: 1.6rem;
    }

    &_title {
      font-family: $fontBodoni;
      font-weight: 600;
      font-size: 3.8rem;
      line-height: 4.8rem;
      margin: 0rem 0rem 5rem 1.2rem;
      @media only screen and (max-width: $xs) {
        font-size: 24px;
        margin: 0rem 0rem 3rem 1.4rem;
      }
    }

    &_buttons-box {
      display: flex;
      align-items: center;
      @media only screen and (max-width: $sm) {
        flex-wrap: wrap;
      }

      &::after {
        content: "";
        background-color: $black;
        height: 1px;
        flex-grow: 100;
        @media only screen and (max-width: $sm) {
          margin-top: -16px;
        }
      }

      .button--oval {
        margin-left: 12px;
        font-family: $fontBodoni;
        @media only screen and (max-width: $sm) {
          margin-bottom: 1.7rem;
        }
      }

      .button--outline {
        font-weight: 700;
      }
    }
  }
}
</style>
